<template>
  <div>
    <p class="font-bold md:text-2xl text-xl mb-4"><span class="text-primary">{{title.split(' ')[0]}}</span> <span class="text-grey-dark">{{title.split(' ')[1]}}</span></p>
    <div class="flex flex-wrap xl:justify-center">
      <div v-for="(item, index) in data" :key="index" class="xl:w-1/4 sm:w-1/2 w-full p-2">
        <div class="border border-dashed border-primary rounded-lg p-4 h-full">
          <p class="mb-1 font-medium text-lg text-primary">{{item.title}} <span class="text-xs">{{item.sub_title}}</span></p>
          <p class="md:text-4xl text-3xl font-bold text-grey-dark text-center">{{item.value}}</p>
          <p class="font-medium text-grey-dark text-center">{{item.percentage}}</p>
        </div>
      </div>
    </div>
    <p class="mt-8 font-bold text-grey-dark"><span class="text-primary">{{chartTitle.split(' ')[0]}}</span> <span>{{chartTitle.substr(chartTitle.indexOf(' ') + 1)}}</span></p>
    <div class="overflow-x-auto">
      <div style="min-width: 1000px;">
        <LineChart
          :id="title"
          :options="chartOptions"
          :chartData="chartData"
          :height="105"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { Line as LineChart } from 'vue-chartjs/legacy'

export default {
  props: ['title', 'data', 'trend', 'chartTitle'],
  data () {
    return {
      Chart,
      chartData: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: this.title.split(' ')[0],
            type: 'line',
            data: this.trend,
            fill: false,
            borderColor: '#1c5bfe',
            borderWidth: 2,
            tension: 0.3,
            pointBorderColor: '#0a3266',
            drawActiveElementsOnTop: true,
            spanGaps: true
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true
      }
    }
  },
  components: {
    LineChart
  }
}
</script>